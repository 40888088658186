<template>
  <div class="AbilityTraining">
    <ul>
      <li>
        <img src="../assets/AbilityTraining/one.jpg" alt="" />
      </li>
      <li>
        <img src="../assets/AbilityTraining/two.jpg" alt="" />
      </li>
      <li>
        <img src="../assets/AbilityTraining/three.jpg" alt="" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style>
img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
</style>